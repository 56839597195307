import { useMediaQuery } from "react-responsive";

import {
  ExplorerBackground,
  ExplorerContentsContainer,
  ExplorerImage,
  ExplorerInfoContainer,
  ExplorerNaviButtonContainer,
  ExplorerSubTitle,
  ExplorerSubTitleContainer,
  ExplorerTitle,
} from "@components/atoms/explorer";

import { NaviButton } from "@components/molecules";
import { URLS } from "~/utils/constant";
import { TitleContainer } from "../atoms/common/title-container";

export const Stream = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:880px)",
  });

  return (
    <ExplorerBackground>
      {!isMobile ? (
        <ExplorerContentsContainer>
          <ExplorerInfoContainer>
            <TitleContainer>
              <ExplorerTitle>해양인을 위한 전세계 실시간 선박</ExplorerTitle>
              <ExplorerTitle>위치 서비스, 맵시스트림</ExplorerTitle>
            </TitleContainer>
            <ExplorerSubTitleContainer>
              <ExplorerSubTitle>
                사용자가 원하는 조건을 설정해 전세계 선박의 실시간 정보를
              </ExplorerSubTitle>

              <ExplorerSubTitle>
                한눈에 확인할 수 있는 웹서비스입니다.
              </ExplorerSubTitle>
            </ExplorerSubTitleContainer>
            {/* <NaviButton title="스트림" url={URLS.stream} /> */}
          </ExplorerInfoContainer>
          <ExplorerImage />
        </ExplorerContentsContainer>
      ) : (
        <ExplorerContentsContainer>
          <ExplorerInfoContainer>
            <TitleContainer>
              <ExplorerTitle>해양인을 위한 전세계</ExplorerTitle>
              <ExplorerTitle>실시간 선박 위치 서비스</ExplorerTitle>
              <ExplorerTitle>맵시스트림</ExplorerTitle>
            </TitleContainer>
            <ExplorerSubTitleContainer>
              <ExplorerSubTitle>
                사용자가 원하는 조건을 설정해 전세계 선박의 실시간
              </ExplorerSubTitle>

              <ExplorerSubTitle>
                정보를 한눈에 확인할 수 있는 웹서비스입니다.
              </ExplorerSubTitle>
            </ExplorerSubTitleContainer>
          </ExplorerInfoContainer>
          <ExplorerImage />
          {/* <ExplorerNaviButtonContainer>
            <NaviButton title="스트림" url={URLS.stream} />
          </ExplorerNaviButtonContainer> */}
        </ExplorerContentsContainer>
      )}
    </ExplorerBackground>
  );
};

export default Stream;
