import styled, { css } from "styled-components";

interface BottomArrowContainerProps {
  scrollIndex: number;
}

export const BottomArrowContainer = styled.div<BottomArrowContainerProps>`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;

  // 마지막 페이지면 화살표 안보이게
  ${(props) =>
    props.scrollIndex === 4 &&
    css`
      display: none;
    `}
`;
