import styled from "styled-components";

export const NaviBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
`;
