import styled from "styled-components";
import SectionBackground from "~/assets/images/section_background.png";

export const IntroBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${SectionBackground}) no-repeat;
  background-size: cover;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;
