import { useMediaQuery } from "react-responsive";

import {
  NaviBackground,
  NaviContentsContainer,
  NaviInfoContainer,
  NaviSubTitle,
  NaviSubTitleContainer,
  NaviTitle,
  NaviTitleContainer,
  NavigationImage,
} from "@components/atoms";

import { NaviButton } from "@components/molecules";
import Navi from "~/assets/images/navigation.png";
import { URLS } from "~/utils/constant";

/**
 * 네비게이션 화면 관련 컴포넌트
 */
export const Navigation = () => {
  // 현재 접속하는게 모바일인지 판단
  const isMobile = useMediaQuery({
    query: "(max-width:880px)",
  });

  // 모바일일 때 화면 레이아웃 다르게 함
  return (
    <NaviBackground>
      {!isMobile ? (
        // PC 화면일 떄
        <NaviContentsContainer>
          <NavigationImage src={Navi} />
          <NaviInfoContainer>
            <NaviTitleContainer>
              <NaviTitle>보다 더 안전한 항해를 위한 선택,</NaviTitle>
              <NaviTitle>맵시내비게이션</NaviTitle>
            </NaviTitleContainer>

            <NaviSubTitleContainer>
              <NaviSubTitle>
                AIS 데이터를 기반으로 선박의 이동경로를 예측하여
              </NaviSubTitle>

              <NaviSubTitle>
                안전한 항해를 제공하는 모바일솔루션입니다.
              </NaviSubTitle>
            </NaviSubTitleContainer>

            {/* <NaviButton title="내비게이션" url={URLS.navigation} /> */}
          </NaviInfoContainer>
        </NaviContentsContainer>
      ) : (
        <NaviContentsContainer>
          {/* 모바일 화면일 때 */}
          <NaviInfoContainer>
            <NaviTitleContainer>
              <NaviTitle>보다 더 안전한</NaviTitle>
              <NaviTitle>항해를 위한,</NaviTitle>
              <NaviTitle>맵시내비게이션</NaviTitle>
            </NaviTitleContainer>

            <NaviSubTitleContainer>
              <NaviSubTitle>
                AIS 데이터를 기반으로 선박의 이동경로를 예측하여
              </NaviSubTitle>

              <NaviSubTitle>
                안전한 항해를 제공하는 모바일솔루션입니다.
              </NaviSubTitle>
            </NaviSubTitleContainer>

            <NavigationImage src={Navi} />
            {/* <NaviButton title="내비게이션" url={URLS.navigation} /> */}
          </NaviInfoContainer>
        </NaviContentsContainer>
      )}
    </NaviBackground>
  );
};
