import { SlArrowRight } from "react-icons/sl";
import { useMediaQuery } from "react-responsive";
import { NaviButtonBackground } from "~/components/atoms/common/navi-button-background";

interface NaviButtonProps {
  title: string;
  url: string;
}

export const NaviButton = ({ title, url }: NaviButtonProps) => {
  const isMobile = useMediaQuery({
    query: "(max-width:880px)",
  });

  return (
    <NaviButtonBackground onClick={() => window.open(url, "_blank")}>
      맵시 {title}
      <SlArrowRight
        size={isMobile ? "0.8rem" : "1rem"}
        style={{ marginLeft: "10px" }}
      />
    </NaviButtonBackground>
  );
};
