import styled from "styled-components";

export const MenuBarContainer = styled.div`
  width: 9rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  top: 40%;
  right: 0;
  margin-right: 10px;
  @media (max-width: 880px) {
    width: auto;
  }
`;
