import styled from "styled-components";

interface InfoTopTitleProps {
  fontSize: string;
  marginBottomSize: string;
}

export const InfoTopTitle = styled.div<InfoTopTitleProps>`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: ${(props) => props.fontSize};
  margin-bottom: ${(props) => props.marginBottomSize};

  @media (max-width: 880px) {
    font-size: 1.9em;
    margin: 4px;
  }
`;
