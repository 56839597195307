import { useMediaQuery } from "react-responsive";

import {
  BlogImage,
  InfoBottomColumnContainer,
  InfoBottomContainer,
  InfoBottomLink,
  InfoBottomRowContainer,
  InfoBottomText,
  InfoBottomTitle,
  InfoButtomIconContainer,
  InfoContainer,
  InfoHompageButton,
  InfoTopContainer,
  InfoTopTitle,
} from "@components/atoms";

import { BsInstagram, BsYoutube } from "react-icons/bs";

import { URLS } from "~/utils/constant";

export const Info = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:880px)",
  });

  return (
    <>
      {!isMobile ? (
        <InfoContainer>
          <InfoTopContainer>
            <InfoTopTitle
              fontSize="var(--font-info-title)"
              marginBottomSize="2rem"
            >
              맵시에 대해 더 알아보고 싶으시다면?
            </InfoTopTitle>

            <InfoHompageButton
              onClick={() => window.open(URLS.home, "_target")}
            >
              회사 홈페이지 바로가기
            </InfoHompageButton>
          </InfoTopContainer>

          <InfoBottomContainer>
            <InfoBottomTitle>지금 바로 시작하세요!</InfoBottomTitle>

            <InfoBottomRowContainer>
              <InfoBottomLink
                onClick={() => window.open(URLS.navigation, "_blank")}
              >
                맵시내비게이션
              </InfoBottomLink>
              <InfoBottomLink
                onClick={() => window.open(URLS.stream, "_blank")}
              >
                맵시스트림
              </InfoBottomLink>
              <InfoBottomLink
                onClick={() => window.open(URLS.connect, "_blank")}
              >
                맵시커넥트
              </InfoBottomLink>
            </InfoBottomRowContainer>

            <InfoButtomIconContainer>
              <BsInstagram
                size="2rem"
                color="white"
                style={{ marginRight: "2rem", cursor: "pointer" }}
                onClick={() => window.open(URLS.instagram, "_blank")}
              />
              <BsYoutube
                size="2.5rem"
                color="white"
                style={{ marginRight: "2rem", cursor: "pointer" }}
                onClick={() => window.open(URLS.youtube, "_blank")}
              />
              <BlogImage onClick={() => window.open(URLS.blog, "_blank")} />
            </InfoButtomIconContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>(주) 맵시</InfoBottomText>
              <InfoBottomText>
                서울 관악구 호암로24길 6 서울대 캠퍼스타운 창업 HERE-RO 5 4층
                401, 402호
              </InfoBottomText>
            </InfoBottomColumnContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>사업자번호 : 459-81-01817</InfoBottomText>
              <InfoBottomText>
                통신판매업 : 제 2022-부산해운대-0567 호
              </InfoBottomText>
            </InfoBottomColumnContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>전자금융분쟁처리</InfoBottomText>
              <InfoBottomText>
                TEL : 02-711-0518 &nbsp; FAX : 0504-370-1044
              </InfoBottomText>
              <InfoBottomText>MAIL : Support@mapseacorp.com</InfoBottomText>
            </InfoBottomColumnContainer>
          </InfoBottomContainer>
        </InfoContainer>
      ) : (
        <InfoContainer>
          <InfoTopContainer>
            <InfoTopTitle
              fontSize="var(--font-info-title)"
              marginBottomSize="2rem"
            >
              맵시에 대해 더
            </InfoTopTitle>
            <InfoTopTitle
              fontSize="var(--font-info-title)"
              marginBottomSize="2rem"
            >
              알아보고 싶으시다면?
            </InfoTopTitle>

            <InfoHompageButton
              onClick={() => window.open(URLS.home, "_target")}
            >
              회사 홈페이지 바로가기
            </InfoHompageButton>
          </InfoTopContainer>

          <InfoBottomContainer>
            <InfoBottomTitle>지금 바로 시작하세요!</InfoBottomTitle>

            <InfoBottomRowContainer>
              <InfoBottomLink
                onClick={() => window.open(URLS.navigation, "_blank")}
              >
                맵시내비게이션
              </InfoBottomLink>
              <InfoBottomLink
                onClick={() => window.open(URLS.stream, "_blank")}
              >
                맵시스트림
              </InfoBottomLink>
              <InfoBottomLink
                onClick={() => window.open(URLS.connect, "_blank")}
              >
                맵시커넥트
              </InfoBottomLink>
            </InfoBottomRowContainer>

            <InfoButtomIconContainer>
              <BsInstagram
                size="2rem"
                color="white"
                style={{ marginRight: "2rem", cursor: "pointer" }}
                onClick={() => window.open(URLS.instagram, "_blank")}
              />
              <BsYoutube
                size="2.5rem"
                color="white"
                style={{ marginRight: "2rem", cursor: "pointer" }}
                onClick={() => window.open(URLS.youtube, "_blank")}
              />
              <BlogImage onClick={() => window.open(URLS.blog, "_blank")} />
            </InfoButtomIconContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>(주) 맵시</InfoBottomText>
              <InfoBottomText>
                서울 관악구 호암로24길 6 서울대 캠퍼스타운 창업 HERE-RO 5 4층
                401, 402호
              </InfoBottomText>
            </InfoBottomColumnContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>사업자번호 : 459-81-01817</InfoBottomText>
              <InfoBottomText>
                통신판매업 : 제 2022-부산해운대-0567 호
              </InfoBottomText>
            </InfoBottomColumnContainer>

            <InfoBottomColumnContainer>
              <InfoBottomText>전자금융분쟁처리</InfoBottomText>
              <InfoBottomText>
                TEL : 02-711-0518 &nbsp; FAX : 0504-370-1044
              </InfoBottomText>
              <InfoBottomText>MAIL : Support@mapseacorp.com</InfoBottomText>
            </InfoBottomColumnContainer>
          </InfoBottomContainer>
        </InfoContainer>
      )}
    </>
  );
};
