import styled, { css } from "styled-components";

interface HeaderContainerProps {
  scrollIndex?: number;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  box-sizing: border-box;
  padding: 0 17vw 0 20vw;
  width: 100vw;
  height: 7vh;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: fixed;
  z-index: 10;
  @media (max-width: 880px) {
    padding: 0 2rem;
    ${(props) =>
      props.scrollIndex! >= 1 &&
      css`
        display: none;
      `}
  }
`;
