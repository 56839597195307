import ReactDOM from "react-dom/client";
import App from "@components/pages/App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyle from "./styles/global_style";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<App />} />
      </Routes>
    </BrowserRouter>
  </RecoilRoot>
);
