import styled from "styled-components";

interface HeaderDivisionProps {
  isFirstPage?: boolean;
}

export const HeaderDivision = styled.div<HeaderDivisionProps>`
  height: 1rem;
  border: 1px solid ${(props) => (props.isFirstPage ? "white" : "black")};
  margin: 0 20px 0 20px;
`;
