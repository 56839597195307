import styled from "styled-components";

export const ConnectSubTitle = styled.div`
  font-family: "NotoSansRegular";
  font-size: var(--font-sub-title-size);
  margin-bottom: 6px;

  @media (max-width: 880px) {
    font-size: 0.8rem;
  }
`;
