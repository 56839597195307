import styled from "styled-components";

export const InfoBottomTitle = styled.div`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: 1.8rem;
  color: white;
  margin-bottom: 1.5rem;

  @media (max-width: 880px) {
    font-size: 1.6rem;
  }
`;
