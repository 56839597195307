import styled from "styled-components";

export const NaviTitle = styled.div`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: var(--font-title-size);
  margin-bottom: 10px;

  @media (max-width: 880px) {
    margin-bottom: 15px;
  }
`;
