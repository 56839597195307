import styled from "styled-components";

interface IntroMainTextProps {
  fontFamily: string;
  fontSize: string;
  marginSize: string;
}

export const IntroMainText = styled.div<IntroMainTextProps>`
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) =>
    props.fontFamily === "NotoSansBold" ? "bold" : "normal"};
  color: white;
  font-size: ${(props) => props.fontSize};
  margin-bottom: ${(props) => props.marginSize};
  text-shadow: 5px 10px 30px rgba(0, 0, 0, 0.2);

  @media (max-width: 880px) {
    line-height: 3rem;
  }
`;
