import styled from "styled-components";

export const InfoBottomLink = styled.div`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: 1rem;
  color: white;
  text-decoration: underline;
  margin-right: 2rem;
  cursor: pointer;

  @media (max-width: 880px) {
    font-size: 0.7rem;
    margin-bottom: 20px;
  }
`;
