import styled from "styled-components";

export const InfoButtomIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 880px) {
    margin-bottom: 0.7rem;
  }
`;
