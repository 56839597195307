import styled from "styled-components";

export const InfoBottomRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.6rem;

  @media (max-width: 880px) {
    flex-direction: column;
    margin-bottom: 3px;
  }
`;
