import { useEffect, useState } from "react";
import ReactPageScroller from "react-page-scroller";

import { BodyContainer } from "@components/atoms";
import { Arrow, Header, MenuBar } from "@components/molecules";
import {
  Connection,
  Stream,
  Info,
  Intro,
  Navigation,
} from "@components/organisms";

function App() {
  // 현재 페이지 번호를 저장하는 상태변수(0 ~ 4)
  // 페이지 이동시 setScrollIndex(페이지 번호) 호출
  const [scrollIndex, setScrollIndex] = useState(0);

  /**
   * 페이지 이동 감지하는 함수
   * @params page: 스크롤 후 페이지 번호
   */
  function handlePageScroll(page: number) {
    console.log(page);
    setScrollIndex(page);
  }

  /**
   * 로고 버튼 클릭(인트로 페이지 이동)
   */
  function handleLogoButtonClick(): void {
    setScrollIndex(0);
  }

  /**
   * 내비게이션 버튼 클릭(내비게이션 페이지 이동)
   */
  function handleNaviButtonClick(): void {
    setScrollIndex(1);
  }

  /**
   * 스트림 버튼 클릭(스트림 페이지 이동)
   */
  function handleExplorerButtonClick(): void {
    setScrollIndex(2);
  }

  /**
   * 커넥트 버튼 클릭(커넥트 페이지 이동)
   */
  function handleConnectButtonClick(): void {
    setScrollIndex(3);
  }

  /**
   * 다운 화살표 버튼 클릭(다음 페이지 이동)
   */
  function handleArrowButtonClick(): void {
    setScrollIndex(scrollIndex + 1);
  }

  /**
   * 사용자가 오버스크롤을 통한 페이지 번호 범위(0~4) 제한
   */
  useEffect(() => {
    if (scrollIndex < 0) {
      setScrollIndex(0);
    }

    if (scrollIndex > 4) {
      setScrollIndex(4);
    }
  }, [scrollIndex]);

  return (
    <BodyContainer>
      <MenuBar
        scrollIndex={scrollIndex}
        naviClick={handleNaviButtonClick}
        explorerClick={handleExplorerButtonClick}
        connectClick={handleConnectButtonClick}
      />
      <Header
        naviClick={handleNaviButtonClick}
        explorerClick={handleExplorerButtonClick}
        connectClick={handleConnectButtonClick}
        logoClick={handleLogoButtonClick}
        scrollIndex={scrollIndex}
      />

      <ReactPageScroller
        pageOnChange={handlePageScroll}
        customPageNumber={scrollIndex}
      >
        <Intro />
        <Navigation />
        <Stream />
        <Connection />
        <Info />
      </ReactPageScroller>
      <Arrow scrollIndex={scrollIndex} onClickEvent={handleArrowButtonClick} />
    </BodyContainer>
  );
}

export default App;
