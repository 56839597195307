import {
  IntroBackground,
  IntroMainText,
  IntroTextContainer,
} from "@components/atoms";

/**
 * 첫 페이지 관련 컴포넌트
 */
export const Intro = () => {
  return (
    <IntroBackground>
      <IntroTextContainer>
        <IntroMainText
          fontFamily="NotoSansBold"
          fontSize="var(--font-intro-title)"
          marginSize="10px"
        >
          CONNECT THE SEA AND THE PEOPLE
        </IntroMainText>

        <IntroMainText
          fontFamily="NotoSansBold"
          fontSize="var(--font-intro-title)"
          marginSize="50px"
        >
          바다와 인류를 지속가능하게 이어주다.
        </IntroMainText>

        <IntroMainText
          fontFamily="NotoSansRegular"
          fontSize="var(--font-intro-sub-title)"
          marginSize="5px"
        >
          해양에 관한 모든 것, 맵시와 함께하세요
        </IntroMainText>
      </IntroTextContainer>
    </IntroBackground>
  );
};
