import { Dot } from "~/components/atoms/common/dot";
import { MenuBarContainer } from "~/components/atoms/common/menu-bar-container";
import { MenuBarItemContainer } from "~/components/atoms/common/menu-bar-item-container";
import { MenuBarText } from "~/components/atoms/common/menu-bar-text";

interface MenuBarProps {
  scrollIndex: number;
  naviClick: () => void;
  explorerClick: () => void;
  connectClick: () => void;
}

export const MenuBar = ({
  scrollIndex,
  naviClick,
  explorerClick,
  connectClick,
}: MenuBarProps) => {
  // 네비게이션, 스트림, 커넥트 페이지에서만 메뉴바 작동
  // 이외에는 메뉴바 비활성화
  const isOpenMenuBar = scrollIndex >= 1 && scrollIndex <= 3;

  return (
    <>
      {isOpenMenuBar ? (
        <MenuBarContainer>
          <MenuBarItemContainer
            num={1}
            scrollIndex={scrollIndex}
            onClick={naviClick}
          >
            <Dot num={1} scrollIndex={scrollIndex} />
            <MenuBarText>맵시내비게이션</MenuBarText>
          </MenuBarItemContainer>

          <MenuBarItemContainer
            num={2}
            scrollIndex={scrollIndex}
            onClick={explorerClick}
          >
            <Dot num={2} scrollIndex={scrollIndex} />
            <MenuBarText>맵시스트림</MenuBarText>
          </MenuBarItemContainer>

          <MenuBarItemContainer
            num={3}
            scrollIndex={scrollIndex}
            onClick={connectClick}
          >
            <Dot num={3} scrollIndex={scrollIndex} />
            <MenuBarText>맵시커넥트</MenuBarText>
          </MenuBarItemContainer>
        </MenuBarContainer>
      ) : (
        <></>
      )}
    </>
  );
};
