import styled from "styled-components";
import ExplorerImagae from "~/assets/images/stream.png";

export const ExplorerImage = styled.img.attrs({ src: ExplorerImagae })`
  width: 50%;
  height: 50vh;
  object-fit: contain;

  @media (max-width: 880px) {
    // width: 80%;
    // height: auto;
  }
`;
