import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import NotoSansBold from "~/assets/fonts/NotoSansKR-Bold.otf";
import NotoSansRegular from "~/assets/fonts/NotoSansKR-Regular.otf";

const GlobalStyle = createGlobalStyle`

    :root {
            --primary-color: #1B79B7;
            --font-intro-title: 2rem;
            --font-intro-sub-title: 1.1rem;
            --font-title-size: 2rem;
            --font-sub-title-size: 0.9rem;
            --font-info-title: 3rem;

        }

    @media (min-width: 880px) {
        :root {
            --font-intro-title: 3rem;
            --font-intro-sub-title: 1.5rem;
            --font-title-size: 1.6rem;
            --font-sub-title-size: 1rem;
            --font-info-title: 2.6rem;
        }
    }

    @media (min-width: 1190px) {
        :root {
            --font-intro-title: 3.2rem;
            --font-intro-sub-title: 1.5rem;
            --font-title-size: 2.6rem;
            --font-sub-title-size: 1.2rem;
            --font-info-title: 3rem;
        }
    }
    ${reset}

    body {
        font-family: "NotoSansBold"
    }


    @font-face {
        font-family: 'NotoSansBold';
        src: local('NotoSansBold'), url(${NotoSansBold}) format('truetype');
        font-style: normal;
    }

    @font-face {
        font-family: 'NotoSansRegular';
        src: local('NotoSansRegular'), url(${NotoSansRegular}) format('truetype');
        font-style: normal;
    }


`;

export default GlobalStyle;
