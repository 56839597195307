import styled from "styled-components";

export const NavigationImage = styled.img`
  width: 40vw;
  height: 40vh;
  object-fit: contain;

  @media (max-width: 880px) {
    width: 90%;
    height: 30vh;
  }
`;
