import styled from "styled-components";

export const InfoTopContainer = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 880px) {
    padding: 0 2rem 0 2rem;
    align-items: flex-start;
  }
`;
