import styled from "styled-components";

interface HeaderMenuTextProps {
  isFirstPage?: boolean;
}

export const HeaderMenuText = styled.div<HeaderMenuTextProps>`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-weight: bold;
  font-size: 1rem;
  color: ${(props) => (props.isFirstPage ? "white" : "black")};

  cursor: pointer;
`;
