import styled from "styled-components";

interface MenuBarItemProps {
  scrollIndex: number; // 페이지 번호
  num: number; // 타겟 페이지 번호
}

export const MenuBarItemContainer = styled.div<MenuBarItemProps>`
  display: flex;
  font-family: "NotoSansRegular";
  font-size: 0.9rem;
  margin-bottom: 30px;
  align-items: center;
  cursor: pointer;
  color: ${(props) =>
    props.scrollIndex === props.num ? "#1E8AD2" : "#767676"};
`;
