import styled from "styled-components";

export const InfoBottomContainer = styled.div`
  width: 100%;
  height: 60%;
  background-color: #1e8ad2;
  box-sizing: border-box;
  padding: 3rem 20vw 2rem 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 880px) {
    padding: 1rem 2rem 1rem 2rem;
  }
`;
