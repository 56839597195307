import { useEffect, useState } from "react";

import {
  HeaderContainer,
  HeaderDivision,
  HeaderMenuContainer,
  HeaderMenuText,
  Logo,
} from "@components/atoms";

import BlackLogoImage from "~/assets/images/black_logo.png";
import LogoImage from "~/assets/images/logo.png";

interface HeaderProps {
  scrollIndex: number;
  naviClick: () => void;
  explorerClick: () => void;
  connectClick: () => void;
  logoClick: () => void;
}

export const Header = ({
  scrollIndex,
  naviClick,
  explorerClick,
  connectClick,
  logoClick,
}: HeaderProps) => {
  const [currentScrollIndex, setCurrentScrollIndex] = useState(scrollIndex);

  useEffect(() => {
    setCurrentScrollIndex(scrollIndex);
  }, [scrollIndex]);

  /**
   * 인트로 페이지일 때 헤더바 색상 흰색으로 바꾸기
   */
  return currentScrollIndex === 0 ? (
    <HeaderContainer scrollIndex={scrollIndex}>
      <Logo onClick={logoClick} src={LogoImage} />
      <HeaderMenuContainer>
        <HeaderMenuText isFirstPage onClick={naviClick}>
          NAVIGATION
        </HeaderMenuText>
        <HeaderDivision isFirstPage />
        <HeaderMenuText isFirstPage onClick={explorerClick}>
          STREAM
        </HeaderMenuText>
        <HeaderDivision isFirstPage />
        <HeaderMenuText isFirstPage onClick={connectClick}>
          CONNECT
        </HeaderMenuText>
      </HeaderMenuContainer>
    </HeaderContainer>
  ) : (
    <HeaderContainer scrollIndex={scrollIndex}>
      <Logo onClick={logoClick} src={BlackLogoImage} />
      <HeaderMenuContainer>
        <HeaderMenuText onClick={naviClick}>NAVIGATION</HeaderMenuText>
        <HeaderDivision />
        <HeaderMenuText onClick={explorerClick}>STREAM</HeaderMenuText>
        <HeaderDivision />
        <HeaderMenuText onClick={connectClick}>CONNECT</HeaderMenuText>
      </HeaderMenuContainer>
    </HeaderContainer>
  );
};
