import { useMediaQuery } from "react-responsive";
import { URLS } from "~/utils/constant";
import { TitleContainer } from "../atoms/common/title-container";

import {
  ConnectBackground,
  ConnectContentsContainer,
  ConnectImage,
  ConnectInfoContainer,
  ConnectSubTitle,
  ConnectSubTitleContainer,
  ConnectTitle,
} from "@components/atoms";

import { NaviButton } from "@components/molecules";

export const Connection = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:880px)",
  });

  return (
    <ConnectBackground>
      {!isMobile ? (
        <ConnectContentsContainer>
          <ConnectImage />
          <ConnectInfoContainer>
            <TitleContainer>
              <ConnectTitle>세상에 모든 해양해운 채용정보</ConnectTitle>
              <ConnectTitle>글로벌 커리어 플롯폼, 커넥트</ConnectTitle>
            </TitleContainer>
            <ConnectSubTitleContainer>
              <ConnectSubTitle>구인구직부터 업계소식까지</ConnectSubTitle>
              <ConnectSubTitle>
                당신의 커리어 여정을 커넥트가 함께합니다.
              </ConnectSubTitle>
            </ConnectSubTitleContainer>

            <NaviButton title="커넥트" url={URLS.connect} />
          </ConnectInfoContainer>
        </ConnectContentsContainer>
      ) : (
        <ConnectContentsContainer>
          <ConnectInfoContainer>
            <TitleContainer>
              <ConnectTitle>해양인들을 위한 맞춤형</ConnectTitle>
              <ConnectTitle>종합 플랫폼,</ConnectTitle>
              <ConnectTitle>맵시커넥트</ConnectTitle>
            </TitleContainer>

            <ConnectSubTitleContainer>
              <ConnectSubTitle>
                구인구직부터 선용품까지 다양한 정보를 교류할 수 있는
              </ConnectSubTitle>

              <ConnectSubTitle>해양해운 전문 플랫폼입니다.</ConnectSubTitle>
            </ConnectSubTitleContainer>
            <ConnectImage />
            <NaviButton title="커넥트" url={URLS.connect} />
          </ConnectInfoContainer>
        </ConnectContentsContainer>
      )}
    </ConnectBackground>
  );
};
