import styled from "styled-components";

export const NaviContentsContainer = styled.div`
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  padding: 0 9rem 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 880px) {
    padding: 0 1rem 0 1rem;
    flex-direction: column;
  }
`;
