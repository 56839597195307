import { IoIosArrowDown } from "react-icons/io";
import { BottomArrowContainer } from "~/components/atoms/common/bottom-arrow-container";

interface AreaProps {
  scrollIndex: number;
  onClickEvent: () => void;
}

/**
 * 다운 화살표
 */
export const Arrow = ({ scrollIndex, onClickEvent }: AreaProps) => {
  return (
    <BottomArrowContainer scrollIndex={scrollIndex}>
      <IoIosArrowDown
        size="2.5rem"
        color={scrollIndex === 0 ? "white" : "#1e1e1e"}
        onClick={onClickEvent}
        cursor="pointer"
      />
    </BottomArrowContainer>
  );
};
