import styled from "styled-components";

export const InfoHompageButton = styled.button`
  width: 14rem;
  height: 3.3rem;
  border: none;
  border-radius: 10px;
  background-color: #1e8ad2;
  color: white;
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;

  &:hover {
    background-color: #156193;
    color: #b2b2b2;
  }

  @media (max-width: 880px) {
    width: 14rem;
    height: 3rem;
    margin-top: 15px;
    font-size: 1rem;
  }
`;
