import styled from "styled-components";
import Connect from "~/assets/images/connect.png";

export const ConnectImage = styled.img.attrs({ src: Connect })`
  width: 36vw;
  height: 50vh;
  // height: 57vh;
  object-fit: contain;

  @media (max-width: 880px) {
    // width: 70vw;
    // height: auto;
  }
`;
