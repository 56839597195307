import styled from "styled-components";

export const NaviButtonBackground = styled.button`
  width: 13rem;
  height: 3.5rem;
  background-color: #1e8ad2;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: 1.3rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #156193;
    color: #b2b2b2;
  }

  @media (max-width: 880px) {
    width: 11rem;
    height: 3rem;
    font-size: 1rem;
  }
`;
