import styled from "styled-components";

export const HeaderMenuContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 880px) {
    display: none;
  }
`;
