import styled from "styled-components";

export const InfoBottomText = styled.div`
  font-family: "NotoSansBold";
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 7px;
  color: white;

  @media (max-width: 880px) {
    font-size: 0.6rem;
  }
`;
