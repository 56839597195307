import styled from "styled-components";

interface DotProps {
  scrollIndex: number;
  num: number;
}

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border: none;
  border-radius: 999px;
  margin-right: 10px;
  background-color: ${(props) =>
    props.scrollIndex === props.num ? "#1E8AD2" : "#767676"};
  transition-duration: 1000;
  transition: background-color 0.5s;
`;
