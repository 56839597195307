import styled from "styled-components";

export const NaviSubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media (max-width: 880px) {
    margin-bottom: 1rem;
  }
`;
